/**
 * Application theme
 *
 * Override and extend smooth-ui theme values
 * https://github.com/smooth-code/smooth-ui/blob/master/packages/shared/core/theme.js
 * https://smooth-ui.smooth-code.com/
 */

import { transparentize } from 'polished';
import { thd, theme } from '@smooth-ui/core-sc';
import settingsApp from 'theme/settingsApp';

/*
If overwriting the default Smooth-UI theme properties, and you're planning on
re-using them throughout the default export below, make sure to define them in
the outer scope, otherwise components attempting to use the value with throw errors
*/
const primary = '#ed0000'; // Red
const secondary = '#9d0000'; // Dark red
const text = '#32343B'; // Dark grey
const black = '#000'; // 100% black

const bronze = '#605139';
const silver = '#828282';
const gold = '#c29710';
const diamond = '#ed0000';

export default {
  // Mandatory
  ...theme,
  settingsApp, // Bring in the merged app settings

  // Resets
  borderRadius: 0,
  error: thd('danger', '#dc3545'),
  gridMaxWidths: { sm: '100%', md: '100%', lg: '100%', xl: '1200px' },
  primary: primary,
  secondary: secondary,
  text: text,
  black: black,
  white: '#fff',
  bronze: bronze,
  silver: silver,
  gold: gold,
  diamond: diamond,

  // Layout
  borderColor: thd('gray300', '#ddd'),
  containerWidth: '1200px',

  // Colours
  defaultFontColor: text,
  // pointsValueColor: '#f90',

  // Input + Buttons
  inputBtnMinWidth: '120px',
  customInputHintColor: theme.gray600,
  customInputHintFontSize: '.875em',
  customInlineInputInvalidColor: theme.red,
  customCheckboxDisabledBackground: '#ced4da',

  // Header + Menu
  customHeaderBackground: '#151d29',
  customHeaderColor: '#f8fbfe',
  customMenuBackground: '#151d29',
  customMenuColor: '#fff',
  customMenuHighlight: secondary,
  customMenuHighlightColor: primary,
  customMenuFocus: transparentize(0.75, '#f4bd19'),
  customSubMenuBackground: '#151d29',
  customMobileMenuOverlayBackground: 'rgba(0, 0, 0, 0.5)',

  // Loading animations
  loadingBarColor: primary || '#f4bd19',
  loadingSpinnerPrimary: '#555',
  loadingSpinnerSecondary: '#eee',

  // Welcome component
  welcomeBackground: primary,
  welcomeColor: '#fff',
  welcomeHighlight: '#fff',

  // Breadcrumbs
  // breadcrumbsBackground: thd('gray200', '#fff'),
  breadcrumbsColor: thd('gray600', '#555'),
  breadcrumbsContainerWidth: thd('containerWidth', '100%'),
  breadcrumbsLinkColor: thd('gray600', '#555'),
  breadcrumbsLinkHoverColor: thd('gray800', '#333'),
  breadcrumbsCurrentLinkColor: thd('gray800', '#333'),
  breadcrumbsFontSize: '0.75rem',

  // Cart
  // miniCartBackground: '#fff',
  cartBadgeBackground: text,
  cartBadgeColor: '#fff',
  cartPointsBalanceBackground: transparentize(0.75, '#369'),
  cartRemainingNegativeColor: thd('danger', '#dc3545'),

  // Rewards
  rewardCategoriesListContainerWidth: thd('containerWidth', '100%'),
  rewardCategoriesFilterContainerWidth: thd('containerWidth', '100%'),
  rewardListContainerWidth: thd('containerWidth', '100%'),
  rewardDetailContainerWidth: thd('containerWidth', '100%'),
  rewardDescription1ContainerWidth: thd('containerWidth', '100%'),
  rewardDescription2ContainerWidth: thd('containerWidth', '100%'),

  // Rewards - Quickview
  // quickviewBackground: '#333',
  // quickviewColor: '#fff',

  // Rewards - Wishlist
  // miniWishlistBackground: '#fff',
  wishlistBadgeBackground: text,
  wishlistBadgeColor: '#fff',
  wishlistContainerWidth: '500px',
  wishlistIconActive: primary,
  // wishlistIconInactive: theme.gray500,

  // Rewards - Highlight (featured/related rewards)
  rewardsHighlightBackground: transparentize(0.75, '#369'),
  rewardsHighlightContainerWidth: thd('containerWidth', '100%'),

  // Statement
  statementHighlightColor: thd('primary', 'red'),
  // statementStatus: {
  //   achieved: '#1e967a',
  //   onTrack: '#1e967a',
  //   close: '#ea7c22',
  //   behind: '#dc402a',
  //   inProgress: '#ea7c22',
  //   timeElapsed: '#32343b',
  // },
  // statementDoughnutBorder: '#eaeaeb',
  // statementDoughnutRemainingTrack: '#fff',

  // Tooltips
  tooltipBackground: '#fff',
  tooltipBorderColor: '#fff',
  tooltipColor: thd('defaultFontColor', theme.gray800),

  // Pagination
  pagerActiveBackground: 'rgba(0,0,0,0.125)',
  pagerActiveColor: '#fff',
  // pagerColor: thd('gray800', 'inherit'),
  pagerBackgroundHover: 'rgba(0,0,0,0.125)',
};
