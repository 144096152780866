import React from 'react';

// Style and SEO
import { css, up, styled, thd } from '@smooth-ui/core-sc';
import { Link } from 'react-router-dom';

import FooterLogo from './images/logo-mirewards-reversed.svg';

const FooterContainer = styled.footer`
  background-color: #000;
  border-top: 8px solid ${thd('primary', 'red')};
  padding: 2rem 1rem 1rem 1rem;
  p {
    color: #fff;
    font-size: 16px;
  }
  ul {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${up(
      'md',
      css`
        flex-direction: row;
      `
    )};
  }
  li {
    font-size: 16px;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    ${up(
      'md',
      css`
        border-right: 1px solid #fff;
        padding: inherit;
        text-align: left;
        &:nth-last-child(1) {
          border-right: none;
        }
      `
    )};
    a {
      color: #fff;
      font-weight: normal;
      text-decoration: none;
      padding: 0.1rem 1rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const FooterLogoWrapper = styled.div`
  display: block;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
  width: 199px;
`;

const RegisterInterestFooter = () => {
  return (
    <FooterContainer>
      <FooterLogoWrapper>
        <img src={FooterLogo} alt="Hewlett Packard Platinum Club" />
      </FooterLogoWrapper>
      <ul>
        <li>
          <Link to="/terms">Terms &amp; Conditions</Link>
        </li>
        <li>
          <Link to="/privacy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/contact">Contact Us</Link>
        </li>
        <li>
          <Link to="/sitemap">Sitemap</Link>
        </li>
      </ul>
      <p className="text--center">
        &copy; Mitsubishi MiREWARDS {new Date().getFullYear()}
      </p>
    </FooterContainer>
  );
};

export default RegisterInterestFooter;
